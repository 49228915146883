import { List, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { navigationItemShape } from './propTypes';
import Container from '../../atoms/Container';
import DesktopMenuItem from './DesktopMenuItem';

export default function DesktopMenu(props) {
  const {
    items,
    onClick,
  } = props;
  const ref = useRef();
  const theme = useTheme();

  return (
    <Container>
      <List
        ref={ref}
        component="nav"
        disablePadding
        sx={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'auto',
          color: theme.palette.secondary.contrastText,
        }}
      >
        {items.map((item) => (
          <DesktopMenuItem
            key={item.path}
            item={item}
            level={1}
            menuRef={ref}
            onClick={onClick}
          />
        ))}
      </List>
    </Container>
  );
}

DesktopMenu.propTypes = {
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func,
};
