import React from 'react';
import { FiInfo, FiPhone, FiMail } from 'react-icons/fi';
import ReactDOMServer from 'react-dom/server';

const styles = {
  icon: {
    lineHeight: '1em',
    verticalAlign: 'middle',
    marginLeft: 3,
    marginRight: 3,
    marginBottom: 5,
    fontSize: '0.75em',
  },
};

const emoticons = Object.freeze({
  info: FiInfo,
  phone: FiPhone,
  mail: FiMail,
});

export function findAndReplaceIcons(text) {
  if (!text) {
    return text;
  }

  Object.keys(emoticons).forEach((icon) => {
    const IconComponent = emoticons[icon];
    const iconHtml = ReactDOMServer
      .renderToString(<span><IconComponent style={styles.icon} /></span>);
    text = text.replaceAll(`:${icon}:`, iconHtml);
  });

  return text;
}
