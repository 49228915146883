import * as yup from 'yup';

import PropTypes from 'prop-types';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import marked from 'marked';
import useContentElement from '../../hooks/useContentElement';
import { findAndReplaceIcons } from './icons';

export default function TypographyElement(props) {
  const {
    data = {
      semanticVariant: 'div',
      displayVariant: 'body1',
    },
    className,
    sx,
    ...otherProps
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { elementData } = useContentElement(
    data,
    TypographyElement.dataSchema,
  );

  const renderer = new marked.Renderer();

  renderer.paragraph = (text) => {
    if (elementData.semanticVariant && elementData.semanticVariant.startsWith('h')) {
      return text;
    }
    return `<p>${text}</p>`;
  };

  // Set default values
  const { displayVariantMobile } = elementData;
  const semanticVariant = elementData.semanticVariant ?? 'div';
  let displayVariant = elementData.displayVariant ?? 'body1';
  const textAlign = elementData.textAlign ?? elementData.textAlign ?? 'left';
  const htmlValue = elementData.value ? marked(findAndReplaceIcons(elementData.value), {
    smartypants: true,
    breaks: true,
    renderer,
  }) : '';

  let displayStyle = {};

  if (isMobile && displayVariantMobile) {
    displayVariant = displayVariantMobile;
  }

  if (displayVariant === 'display1') {
    displayStyle = theme.typography.display1;
  } else if (displayVariant === 'display2') {
    displayStyle = theme.typography.display2;
  } else if (displayVariant === 'display3') {
    displayStyle = theme.typography.display3;
  }

  return (
    <Typography
      {...otherProps}
      align={textAlign}
      className={className}
      component={semanticVariant}
      dangerouslySetInnerHTML={{ __html: htmlValue }}
      sx={{
        ...sx,
        ...displayStyle,
        '& p + ul': {
          marginTop: theme.spacing(-2),
          marginBottom: theme.spacing(2),
        },
        hyphens: 'auto',
        '& p:not(:last-child)': {
          marginBottom: theme.spacing(2),
        },
        '& a[href]': {
          color: theme.palette.primary.main,
          textDecoration: 'none !important',
          backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 25%, rgba(255,255,255,0) 0%)`,
          backgroundPosition: 'bottom',
          backgroundSize: '4px 1px',
          backgroundRepeat: 'repeat-x',
        },
        '& a[href]:hover, a[href]:focus': {
          color: theme.palette.primary.dark,
          backgroundImage: `linear-gradient(to right, ${theme.palette.primary.dark} 25%, ${theme.palette.primary.dark} 0%)`,
        },

      }}
      variant={displayVariant}
    />
  );
}

const SEMANTIC_VARIANTS = [
  null,
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'div',
  'span',
];
const DISPLAY_VARIANTS = [
  null,
  'display1',
  'display2',
  'display3',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'caption',
  'button',
  'overline',
  'srOnly',
  'inherit',
];
const TEXT_ALIGNMENTS = [
  null,
  'left',
  'center',
  'right',
  'justify',
];

TypographyElement.typeName = 'ComponentElementsTypographyElement'; // Strapi element type
TypographyElement.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string,
    semanticVariant: PropTypes.oneOf(SEMANTIC_VARIANTS),
    displayVariant: PropTypes.oneOf(DISPLAY_VARIANTS),
    displayVariantMobile: PropTypes.oneOf(DISPLAY_VARIANTS),
    textAlign: PropTypes.oneOf(TEXT_ALIGNMENTS),
  }).isRequired,
  color: PropTypes.string,
};
TypographyElement.dataSchema = yup.object().shape({
  value: yup.string().nullable(),
  semanticVariant: yup.string().nullable().oneOf(SEMANTIC_VARIANTS),
  displayVariant: yup.string().nullable().oneOf(DISPLAY_VARIANTS),
  displayVariantMobile: yup.string().nullable().oneOf(DISPLAY_VARIANTS),
  textAlign: yup.string().nullable().oneOf(TEXT_ALIGNMENTS),
});
TypographyElement.graphQlSchema = `
  id
  value
  semanticVariant
  displayVariant
  displayVariantMobile
  textAlign
`;
