import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

export default function Hidden(props) {
  const { children, dir, size } = props;

  const hidden = useMediaQuery((theme) => theme.breakpoints[dir](size));

  if (hidden) {
    return null;
  }

  return children;
}

// Define prop types for validation
Hidden.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  dir: PropTypes.oneOf(['up', 'down']).isRequired, // only up/down are valid for hiding
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired,
};
