import NativeLink from 'next/link';
import React from 'react';

export default function Link(props) {
  const { passHref } = props;
  const linkProps = {
    ...props,
    passHref: passHref ?? true,
  };

  if (linkProps.href === '#') {
    linkProps.href = {
      pathname: '#',
    };
  }

  return (
    <NativeLink legacyBehavior {...linkProps} />
  );
}

Link.propTypes = {
  ...NativeLink.propTypes,
};
