import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import de from './de.json';
import es from './es.json';
import ru from './ru.json';
import tr from './tr.json';

const resources = {
  en,
  de,
  es,
  ru,
  tr,
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'de',
    fallbackLng: 'de',
    supportedLngs: Object.keys(resources),
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export const languages = Object.keys(resources);
