/* eslint-disable max-len */
import 'klaro/dist/klaro.css';
import '@lib/gdpr';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { zIndices } from '@/theme';

export default function GdprConsent({ hasKlaroConsent }) {
  const theme = useTheme();
  const [showMockUI, setShowMockUI] = useState(!hasKlaroConsent);

  useEffect(() => {
    setShowMockUI(false);
  }, []);

  return (
    <>
      {showMockUI && <KlaroServerSideDialog />}
      <style global jsx>
        {`
          .klaro .cookie-notice:not(.cookie-modal-notice),
          .klaro .cookie-modal {
            z-index: ${zIndices.gdprConsent} !important; /* Place above ProvenExpert bottom bar */
            border-radius: ${theme.shape.borderRadius}px !important;
            min-height: 466px;
            max-height: calc(100vh - var(--notice-bottom, 20px) * 2);
            outline: none !important;
          }

          .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
          }

          .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
            justify-content: center;
          }

          .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body > p {
            overflow-y: auto;
          }

          .klaro .cm-btn {
            border-radius: ${theme.shape.borderRadius}px !important;
            min-width: 100px;
            height: auto !important;
          }

          .klaro .cm-link {
            color: ${theme.palette.primary.main} !important;
          }

          .klaro .cm-btn.cm-btn-success,
          .klaro .cm-btn.cm-btn-success-var {
            background: ${theme.palette.primary.main} !important;
          }

          @media only screen and (max-width: 600px) {
            .klaro .cookie-notice:not(.cookie-modal-notice) {
              position: fixed !important;
              top: 20px !important;
              right: 20px !important;
              bottom: 20px !important;
              left: 20px !important;
              width: calc(100% - 40px) !important;
              max-height: 100%;
            }

            .klaro .cookie-notice:not(.cookie-modal-notice),
            .klaro .cookie-modal {

            }

            .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
              padding-top: 10px !important;
              padding-bottom: 10px !important;
            }
          }

          div[data-type="placeholder"][data-name="youtube"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          div[data-type="placeholder"][data-name="youtube"] .klaro.cm-as-context-notice {
            padding: 0;
          }

          .klaro .cookie-modal a,
          .klaro .context-notice a,
          .klaro .cookie-notice a {
            color: ${theme.palette.primary.main} !important;
            background: rgba(255, 255, 255, 0.35);
            border-radius: ${theme.shape.borderRadius / 2}px;
          }

          .klaro .cookie-modal a::before,
          .klaro .cookie-modal a::after,
          .klaro .context-notice a::before,
          .klaro .context-notice a::after,
          .klaro .cookie-notice a::before,
          .klaro .cookie-notice a::after {
            content: "";
            width: ${theme.shape.borderRadius / 2}px;
            display: inline-block;
          }

          .klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
          .klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider,
          .klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
            background-color: ${theme.palette.primary.main} !important;
          }

          .klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
          .klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider,
          .klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
            background-color: ${theme.palette.primary.main} !important;
          }

          .klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
          .klaro .context-notice .cm-list-input:checked + .cm-list-label .slider,
          .klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
            background-color: ${theme.palette.primary.main} !important;
          }

          .klaro .cm-service .purposes {
            display: none !important;
          }

          .klaro .cm-list-description {
            width: 100%;
            overflow-x: auto;
          }

          .klaro table.service-details {
            border: none;
            border-spacing: 0;
          }

          .klaro table.service-details tr {
          }

          .klaro table.service-details td:first-child {
            white-space: nowrap;
            width: 1px;
            font-weight: 700;
            padding: 0;
            padding-right: 7px;
            vertical-align: top;
          }

          .klaro.cm-as-context-notice .cm-buttons .cm-btn:first-child {
            display: none !important;
          }

          .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
            width: 100% !important;
            padding-top: 0.65rem !important;
            padding-bottom: 0.65rem !important;
          }

          .klaro .klaro-brand-logo {
            display: flex;
            justify-content: center;
            margin-bottom: ${theme.spacing(3)};
          }

          .klaro .klaro-brand-logo img {
            display: block;
            width: 150px;
            height: auto;
          }

          @media only screen and (max-width: 600px) {
            .klaro .cookie-modal .cm-modal .cm-footer-buttons {
              flex-flow: column !important;
            }

            .klaro .cookie-modal .cm-modal .cm-footer-buttons .cm-btn {
              margin-bottom: 15px;
            }
          }
        `}
      </style>
    </>
  );
}

function KlaroServerSideDialog() {
  return (
    <div className="klaro-mock" id="klaro2" style={{ display: 'block' }}>
      <div className="klaro" lang="de">
        <div
          aria-describedby="id-cookie-notice"
          aria-labelledby="id-cookie-title"
          className="cookie-notice"
          id="klaro-cookie-notice"
          role="dialog"
        >
          <div className="cn-body" style={{ color: 'white' }}>
            <div
              id="id-cookie-notice2"
              style={{
                marginTop: '0.7em',
                marginBottom: '0.5em',
                overflowY: 'auto',
              }}
            >
              <div className="klaro-brand-logo">
                <img
                  alt="Schimmel Automobile"
                  height="40"
                  id="id-cookie-title"
                  loading="lazy"
                  src="/images/brand/brand-logo-inverted.svg"
                  width="150"
                />
              </div>
              Um unser Webangebot zu verbessern und diese Seite optimal für unsere Besucher zu gestalten,setzen wir und auch unsere Partner, dessen Dienste wir nutzen, Technologien wie Cookies ein.Auch ein Austausch von IP-Adressen unserer Webseitenbesucher mit unseren Partnern kann stattfinden.
              <br />
              <br />
              Die Setzung einiger Cookies ist zwingend erforderlich. Für bestimmte Dienste benötigen wir Ihre Einwilligung.
              <br />
              <br />
              Durch den Klick auf „Alle Cookies akzeptieren“, willigen Sie (jederzeit für die Zukunft widerruflich) in alle Datenverarbeitungen (Setzung von Cookies und Übermittlung der IP-Adresse an Partner) ein.
              <br />
              <br />
              Durch den Klick „ Alle optionalen Cookies ablehnen“ werden alle nicht zwingend notwendigen Cookies nicht gesetzt und Verbindungen unterbunden. Die Nutzung unserer Webseite ist dann stark eingeschränkt.
              <br />
              <br />
              Durch den Klick auf „ Lassen Sie mich wählen“ können Einstellungen geändert und der Datenverarbeitung eingewilligt werden. Ihre Auswahl kann jederzeit angepasst werden.
              <br />
              <br />
              Hinweis auf Verarbeitung der Daten in den USA (z.B. durch Google, Facebook, Youtube): Durch den Klick auf Alle Cookies akzeptieren oder bei der entsprechenden Auswahl eines Anbieters, willigen Sie zugleich darin ein, dass Ihre Daten in den USA verarbeitet werden. Die USA wird als ein Land mit einem nicht ausreichenden Datenschutzniveau angesehen. Es besteht u.a. das Risiko, dass Ihre Daten durch US-Behörden, zu Kontroll- und zu Überwachungszwecken, möglicherweise auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden können. Bei dem Klick auf „Alle optionalen Cookies ablehnen“, findet keine Datenübermittlung statt.
              <br />
              <br />
              Weitere Informationen finden Sie unter
              {' '}
              <a href="/datenschutz">
                Datenschutz
              </a>
              . Zum
              {' '}
              <a href="/impressum">Impressum</a>
              .
            </div>
            <div className="cn-ok">
              <a className="cm-link cn-learn-more" href="#">Lassen Sie mich wählen</a>
              <div className="cn-buttons">
                <button className="cm-btn cm-btn-danger cn-decline" type="button">Alle optionalen Cookies ablehnen</button>
                <button className="cm-btn cm-btn-success" type="button">Alle Cookies akzeptieren</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
