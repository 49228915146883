import React from 'react';
import { Chip, useTheme } from '@mui/material';
import {
  FiBatteryCharging, FiHelpCircle, FiLoader, FiPlus, FiStar, FiSun, FiTag, FiZap,
} from 'react-icons/fi';

export default function MenuItemBadge(props) {
  const { label, icon } = props;
  const theme = useTheme();

  return (
    <Chip
      color="primary"
      icon={icon ? mapBadgeIcon(icon) : null}
      label={label}
      size="small"
      sx={{
        marginLeft: theme.spacing(1),
        fontSize: '0.65rem',
        height: 18,
      }}
    />
  );
}

function mapBadgeIcon(icon) {
  switch (icon) {
    case 'plus':
      return <FiPlus />;
    case 'help':
      return <FiHelpCircle />;
    case 'loader':
      return <FiLoader />;
    case 'star':
      return <FiStar />;
    case 'tag':
      return <FiTag />;
    case 'sun':
      return <FiSun />;
    case 'zap':
      return <FiZap />;
    case 'battery':
      return <FiBatteryCharging />;
    default:
      return null;
  }
}
