import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@mui/material';
import { navigationItemShape } from './propTypes';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

export default function MainMenu(props) {
  const {
    desktop,
    items,
    locales,
    mobile,
    onClick,
    pageUrls,
    sx,
  } = props;
  const menuItems = items.filter((item) => item.path !== '/' && item.props?.hidden !== 'all');
  const desktopItems = menuItems.filter((m) => m.props?.hidden !== 'desktop');
  const mobileItems = menuItems.filter((m) => m.props?.hidden !== 'mobile');

  const handleItemClick = (e) => {
    if (onClick) onClick(e);
  };

  return (
    <Box
      sx={{
        ...sx,
        ...(desktop && ({
          borderTopColor: 'rgba(0, 0, 0, 0.05)',
          borderTopWidth: '0.1rem',
          borderTopStyle: 'solid',
        })),
        ...(mobile && ({})),
      }}
    >
      {desktop && (
        <DesktopMenu
          items={desktopItems}
          onClick={handleItemClick}
        />
      )}
      {mobile && (
        <MobileMenu
          items={mobileItems}
          locales={locales}
          onClick={handleItemClick}
          pageUrls={pageUrls}
        />
      )}
    </Box>
  );
}

MainMenu.propTypes = {
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func,
};
