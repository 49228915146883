/* eslint-disable */
import { useConsent } from '@lib/gdpr';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { GTM_ID } from '@/constants';

export default function Tracking() {
  const router = useRouter();
  const hasConsentGoogleAnalytics = useConsent('googleAnalytics');
  const hasConsentFacebook = useConsent('facebook');
  const hasConsentTikTok = useConsent('tiktok');
  const hasConsentLinkedIn = useConsent('linkedIn');

  useEffect(() => {
    const handleRouteChange = (url) => {
      /*gtag('config', 'G-4SDY8JJXQ1', {
        page_path: url,
      })
      window.dataLayer.push({
        event: 'page_view',
        page: window.location.href,
      });*/
      fbq('track', 'PageView');
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  useEffect(() => {
    const scripts = [];

    if (hasConsentGoogleAnalytics) {
      addGoogleAnalytics(scripts);
      addGoogleAds(scripts);
    }

    if (hasConsentFacebook) {
      addFacebookPixel(scripts);
    }

    if (hasConsentLinkedIn) {
      addLinkedInPixel();
    }

    if (hasConsentTikTok) {
      addTikTokPixel();
    }

    for (const script of scripts) {
      document.body.appendChild(script);
    }

    return () => {
      for (const script of scripts) {
        document.body.removeChild(script);
      }
    };
  }, [
    hasConsentGoogleAnalytics,
    hasConsentFacebook,
    hasConsentLinkedIn,
    hasConsentTikTok,
  ]);

  return (
    <>
      {hasConsentGoogleAnalytics && (
        <GoogleTagManager
          gtmId={GTM_ID}
          xgtmScriptUrl="https://analytics.schimmel-automobile.de/gtm.js"
        />
      )}
    </>
  );
}

export function trackEvent(name, params) {
  if (fbStandardEvents.includes(name)) {
    fbq('track', name, params);
  } else {
    fbq('trackCustom', name, params);
  }
}

function addGoogleAnalytics(scripts) {
  gtag('consent', 'update', {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: 'granted',
    security_storage: 'granted',
  });
  gtag('set', 'url_passthrough', true);

  /*window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

  function gtag() {
    window.dataLayer.push(arguments);
  }

  gtag('config', 'GTM-MV4QGRR', {
    server_container_url: 'https://server-side-tagging-s63ocazxva-uc.a.run.app',
  });
  gtag('consent', 'default', {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: 'granted',
    security_storage: 'granted',
  });
  gtag('set', 'url_passthrough', true);

  const script2 = document.createElement('script');
  script2.async = true;
  script2.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-MV4QGRR';
  scripts.push(script2);*/
}

function addGoogleAds(scripts) {
  window.gtag?.('js', new Date());
  window.gtag?.('config', 'AW-927505770', { anonymize_ip: true });

  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-927505770';
  scripts.push(script);
}

function addFacebookPixel(scripts) {
  window.fbq = window._fbq = fbq;
  fbq('init', '606882143143767');
  fbq('track', 'PageView');

  fbq('init', '5876036342502836');
  fbq('track', 'PageView');

  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://connect.facebook.net/en_US/fbevents.js';
  scripts.push(script);
}

function addLinkedInPixel() {
  const _linkedin_partner_id = '5267250';
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);

  !(function (l) {
    if (!l) {
      window.lintrk = function (a, b) {
        window.lintrk.q.push([a, b]);
      };
      window.lintrk.q = [];
    }
    const s = document.getElementsByTagName('script')[0];
    const b = document.createElement('script');
    b.type = 'text/javascript';
    b.async = true;
    b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
    s.parentNode.insertBefore(b, s);
  }(window.lintrk));
}

function addTikTokPixel() {
  !(function (w, d, t) {
    // eslint-disable-next-line no-sequences,prefer-rest-params,no-plusplus,no-var,vars-on-top,block-scoped-var
    w.TiktokAnalyticsObject = t;
    const ttq = w[t] = w[t] || [];
    ttq.methods = ['page', 'track', 'identify', 'instances', 'debug', 'on', 'off', 'once', 'ready', 'alias', 'group', 'enableCookie', 'disableCookie'], ttq.setAndDefer = function (t, e) {
      t[e] = function () {
        t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
      };
    };
    for (let i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
    ttq.instance = function (t) {
      for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
      return e;
    }, ttq.load = function (e, n) {
      const i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
      ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = i, ttq._t = ttq._t || {}, ttq._t[e] = +new Date(), ttq._o = ttq._o || {}, ttq._o[e] = n || {};
      const o = document.createElement('script');
      o.type = 'text/javascript', o.async = !0, o.src = `${i}?sdkid=${e}&lib=${t}`;
      const a = document.getElementsByTagName('script')[0];
      a.parentNode.insertBefore(o, a);
    };

    ttq.load('CJAEGPRC77UFNS3Q9O4G');
    ttq.page();
  }(window, document, 'ttq'));
}

function gtag() {
  if (typeof window === 'undefined') {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

gtag('consent', 'default', {
  ad_storage: 'denied',
  ad_user_data: 'denied',
  ad_personalization: 'denied',
  analytics_storage: 'denied',
  functionality_storage: 'denied',
  personalization_storage: 'denied',
  security_storage: 'denied',
});

function fbq() {
  fbq.callMethod
    // eslint-disable-next-line prefer-spread
    ? fbq.callMethod.apply(fbq, arguments)
    : fbq.queue.push(arguments);
}

fbq.push = fbq;
fbq.loaded = true;
fbq.version = '2.0';
fbq.queue = [];
const fbStandardEvents = [
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishlist',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'InitiateCheckout',
  'Lead',
  'Purchase',
  'Schedule',
  'Search',
  'StartTrial',
  'SubmitApplication',
  'Subscribe',
  'ViewContent',
];
