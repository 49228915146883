import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@mui/material';

export default function Backdrop(props) {
  const { children, sx } = props;

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 1)',
        '@supports (backdrop-filter: blur(20px))': {
          backdropFilter: 'saturate(180%) blur(20px)',
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

Backdrop.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
