import {
  IconButton, ListItem, ListItemText, Popover, useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiTag } from 'react-icons/fi';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import { navigationItemShape } from './propTypes';
import Link from '../../atoms/Link';
import { easings, timings, zIndices } from '@/theme';
import DesktopMenuItemList from './DesktopMenuItemList';
import DesktopMenuItemMegaMenu from './DesktopMenuItemMegaMenu';

const { publicRuntimeConfig } = getConfig();

const POPOVER_CLOSE_TIMEOUT = 350;
const DEV_FORCE_MEGA_MENU_OPEN = false;

export default function DesktopMenuItem(props) {
  const {
    item,
    menuRef,
    onClick,
  } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mouseState] = useState({
    isMouseOver: false,
  });
  const [closeTimeout, setCloseTimeout] = useState(null);
  const targetEl = useRef();

  const hasItems = item.items?.length > 0 || Boolean(item.megaMenu);
  const hasPopover = hasItems;
  const isPopoverVisible = Boolean(anchorEl);

  useEffect(() => {
    if (publicRuntimeConfig.isDev && item.megaMenu && DEV_FORCE_MEGA_MENU_OPEN) {
      handleTogglePopover({
        preventDefault: () => { },
        stopPropagation: () => { },
      });
    }

    return () => {
      clearTimeout(closeTimeout);
    };
  }, [
    closeTimeout,
  ]);

  const handlePopoverOpen = (event) => {
    clearTimeout(closeTimeout);

    if (!hasPopover) {
      return;
    }

    setAnchorEl(event.currentTarget);
    // setAnchorEl(menuRef.current);
  };
  const handlePopoverClose = (e) => {
    if (!hasPopover) {
      return;
    }

    handlePopoverMouseOut();
    setCloseTimeout(setTimeout(() => {
      if (mouseState.isMouseOver) {
        return;
      }

      setAnchorEl(null);
      onClick(e);
    }, POPOVER_CLOSE_TIMEOUT));
  };
  const handleTogglePopover = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!hasPopover) {
      return;
    }

    if (anchorEl) {
      handlePopoverClose(e);
    } else {
      setAnchorEl(targetEl.current);
      // setAnchorEl(menuRef.current);
    }
  };
  const handlePopoverMouseOver = () => {
    clearTimeout(closeTimeout);
    mouseState.isMouseOver = true;
  };
  const handlePopoverMouseOut = () => {
    mouseState.isMouseOver = false;
  };

  return (
    <>
      <Link
        key={item.path}
        href={item.path}
        legacyBehavior
      >
        <ListItem
          ref={targetEl}
          button
          className={`${item.title.toLowerCase().replace(/\s/g, '')}_tag`}
          component="a"
          dense
          onClick={handlePopoverClose}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{
            width: 'auto',
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            ...(isPopoverVisible && ({
              '&::after': {
                opacity: 1,
                transition: `opacity ${timings.defaultAnimationTiming}ms 100ms ${easings.defaultEasingCss}`,
              },
            })),
            ...(item.highlighting === 'promotion' && ({
              color: theme.palette.primary.main,
            })),
          }}
        >
          <ListItemText
            primaryTypographyProps={{
              variant: 'subtitle2',
            }}
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {item.title}
          </ListItemText>
          {hasItems && (
            <IconButton aria-label="expand" onClick={handleTogglePopover} size="small">
              <FiChevronDown
                style={{
                  transition: theme.transitions.create('all', {
                    duration: theme.transitions.duration.complex,
                  }),
                  ...(isPopoverVisible && ({
                    transform: 'rotate(180deg)',
                  })),
                }}
              />
            </IconButton>
          )}
          {item.highlighting === 'promotion' && (
            <IconButton
              aria-label="promotion"
              size="small"
            >
              <FiTag
                style={{
                  marginLeft: theme.spacing(1),
                  transition: theme.transitions.create('all', {
                    duration: theme.transitions.duration.complex,
                  }),
                  color: theme.palette.primary.main,
                }}
              />
            </IconButton>
          )}
        </ListItem>
      </Link>
      {hasPopover && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          disableRestoreFocus
          elevation={24}
          open={isPopoverVisible}
          slotProps={{
            paper: {
              onMouseOver: handlePopoverMouseOver,
              onMouseOut: handlePopoverMouseOut,
              onMouseLeave: handlePopoverClose,
              sx: {
                pointerEvents: 'auto',
                marginTop: 1,
                display: 'flex',
                '& > div:not(:last-child)': {
                  borderRight: '0.1rem solid rgba(0, 0, 0, 0.05)',
                },
              },
            },
          }}
          sx={{
            pointerEvents: 'none',
            zIndex: `${zIndices.desktopMenuPopover} !important`,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {item.megaMenu
            ? (
              <DesktopMenuItemMegaMenu
                item={item.megaMenu}
                onClick={handlePopoverClose}
              />
            )
            : (
              <DesktopMenuItemList
                items={item.items}
                onClick={handlePopoverClose}
              />
            )}
        </Popover>
      )}
    </>
  );
}

DesktopMenuItem.propTypes = {
  item: navigationItemShape.isRequired,
  // Events
  onClick: PropTypes.func,
};
