import * as yup from 'yup';

import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import useContentElement from '../../hooks/useContentElement';

export default function LinkElement(props) {
  const {
    data, children, onClick, ...otherProps
  } = props;
  const { elementData } = useContentElement(
    data,
    LinkElement.dataSchema,
  );

  const { isNewWindow } = elementData;
  const url = elementData.resolvedUrl ?? '#';
  let rel = null;

  if (url && url.startsWith('http')) {
    rel = 'noopener noreferrer';
  }

  return (
    <Link
      href={url}
      legacyBehavior
      passHref
      {...otherProps}
    >
      {React.cloneElement(children, {
        target: isNewWindow ? '_blank' : undefined,
        rel,
        onClick,
      })}
    </Link>
  );
}

LinkElement.typeName = 'LinkElement'; // Strapi element type
LinkElement.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string,
    resolvedUrl: PropTypes.string,
    isNewWindow: PropTypes.bool,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
LinkElement.defaultProps = {
  data: {
    isNewWindow: false,
  },
};
LinkElement.dataSchema = yup.object().shape({
  url: yup.string().nullable(),
  resolvedUrl: yup.string().nullable(),
  isNewWindow: yup.boolean().nullable(),
});
LinkElement.graphQlSchema = `
  id
  url
  hash
  resolvedUrl
  isNewWindow
`;
