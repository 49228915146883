import React from 'react';
import MuiContainer from '@mui/material/Container';

export default function Container(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiContainer {...props} />
  );
}

Container.propTypes = {
  ...MuiContainer.propTypes,
};
