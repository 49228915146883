import { FiArrowDown } from 'react-icons/fi';
import { FormControl, InputLabel, Select as MuiSelect } from '@mui/material';
import React, { useMemo } from 'react';
import slugify from 'slugify';

export default function Select(props) {
  const {
    id,
    color,
    label,
    sx,
    sxFormControl,
    ...otherProps
  } = props;
  const slug = useMemo(() => slugify(`label-${id ?? label ?? ''}`), [id, label]);

  return (
    <FormControl color={color} fullWidth sx={sxFormControl}>
      <InputLabel color={color} id={slug} size="small">
        {label}
      </InputLabel>
      <MuiSelect
        color={color}
        IconComponent={FiArrowDown}
        label={label}
        labelId={slug}
        size="small"
        sx={{
          ...sx,
          '&.MuiInputBase-colorSecondary .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.secondary.main,
          },
        }}
        {...otherProps}
      />
    </FormControl>
  );
}

Select.propTypes = {
  ...MuiSelect.propTypes,
};
