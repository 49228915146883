import { List, ListItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { navigationItemShape } from './propTypes';
import Link from '../../atoms/Link';

export default function DesktopMenuItemList(props) {
  const {
    items = [],
    onClick,
  } = props;

  return (
    <List
      sx={{
        minWidth: 300,
      }}
    >
      {items.map((item) => (
        <Item
          key={item.path}
          item={item}
          level={1}
          onClick={onClick}
        />
      ))}
    </List>
  );
}

DesktopMenuItemList.propTypes = {
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func,
};

function Item(props) {
  const {
    item,
    level = 1,
    onClick,
  } = props;
  const hasItems = item.items?.length > 0;

  return (
    <>
      <Link href={item.path} legacyBehavior>
        <ListItem
          button
          component="a"
          onClick={onClick}
        >
          <ListItemText>
            {item.title}
          </ListItemText>
        </ListItem>
      </Link>
      {hasItems && (
        <List
          component="div"
          disablePadding
          sx={{
            mb: 3,
            pl: 4,
          }}
        >
          {item.items.map((subItem) => (
            <Item
              key={subItem.path}
              item={subItem}
              level={level + 1}
              onClick={onClick}
            />
          ))}
        </List>
      )}
    </>
  );
}

Item.propTypes = {
  item: navigationItemShape,
  level: PropTypes.number,
  // Events
  onClick: PropTypes.func,
};
