import { List } from '@mui/material';
import PropTypes from 'prop-types';
import LanguagePopover from '@components/organisms/LanguagePopover';
import Hidden from '@components/atoms/Hidden';
import { useState } from 'react';
import { navigationItemShape } from './propTypes';
import MobileMenuItem from './MobileMenuItem';

export default function MobileMenu(props) {
  const {
    items,
    onClick,
    locales,
    pageUrls,
  } = props;
  const [openPopover, setOpenPopover] = useState(false);

  const handleClosePopover = () => {
    setOpenPopover(false);
    onClick();
  };
  const handleOpen = () => {
    setOpenPopover(true);
  };

  return (
    <List component="nav">
      <Hidden dir="up" size="md">
        <LanguagePopover
          handleOpen={handleOpen}
          locales={locales ?? []}
          onClose={handleClosePopover}
          open={openPopover}
          pageUrls={pageUrls}
        />
      </Hidden>
      {items.map((item) => (
        <MobileMenuItem
          key={item.path}
          item={item}
          level={1}
          onClick={onClick}
        />
      ))}
    </List>
  );
}

MobileMenu.propTypes = {
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func,
};
