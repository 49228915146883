import PropTypes from 'prop-types';
import React from 'react';
import Image from '@components/atoms/Image2';

export default function BrandLogo(props) {
  // eslint-disable-next-line no-unused-vars
  const {
    color = 'default',
    style,
    className,
  } = props;
  const src = colorSchemeImageMapping[color];

  return (
    <Image
      alt="Schimmel Automobile"
      className={className}
      height={140}
      src={src}
      style={style}
      width={529}
    />
  );
}

BrandLogo.propTypes = {
  color: PropTypes.oneOf(['default', 'inverted']),
};

const colorSchemeImageMapping = {
  default: '/images/brand/brand-logo-default.svg',
  inverted: '/images/brand/brand-logo-inverted.svg',
};
