import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@mui/material/styles';
import Link from '../atoms/Link';

const StyledLink = styled('a')(({ theme }) => ({
  opacity: 0.75,
  transition: 'opacity 250ms ease-in-out',
  '&:hover, &:focus': {
    opacity: 1,
  },
}));

export default function FooterMenu(props) {
  const { items } = props;

  return items.map((item) => (
    <Typography
      key={item.label}
      color="secondary"
      component="div"
      sx={{ mb: 2 }}
      variant="body2"
    >
      <Link href={item.href || '/'} legacyBehavior>
        <StyledLink className={item.className} onClick={item.onClick}>
          {item.label}
        </StyledLink>
      </Link>
    </Typography>
  ));
}

FooterMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    isExternal: PropTypes.bool,
  })),
};
