import { LinkElement } from '@plugins/next-cms-core';
import PropTypes from 'prop-types';

const navigationItemShapeInternal = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};
navigationItemShapeInternal.items = PropTypes.arrayOf(PropTypes.shape(navigationItemShapeInternal));

export const navigationItemShape = PropTypes.shape(navigationItemShapeInternal);

export const megaMenuItemShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  badge: PropTypes.string,
  badge_icon: PropTypes.string,
  image: PropTypes.shape({
    formats: PropTypes.shape({
      thumbnail: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }),
  link: PropTypes.shape(LinkElement.propTypes),
});
export const megaMenuGroupShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  items: PropTypes.arrayOf(megaMenuItemShape),
});
export const megaMenuShape = PropTypes.shape({
  items: PropTypes.arrayOf(megaMenuItemShape),
  groups: PropTypes.arrayOf(megaMenuGroupShape),
});

navigationItemShapeInternal.megaMenu = megaMenuShape;
