import {
  Box,
  Divider,
  Grid2,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { LinkElement } from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import React from 'react';
import ModelName from '@components/vehiclefleet/ModelName';
import Image from '@components/atoms/Image2';
import { megaMenuGroupShape, megaMenuItemShape, megaMenuShape } from './propTypes';
import MenuItemBadge from './MenuItemBadge';

export default function DesktopMenuItemMegaMenu(props) {
  const {
    item,
    onClick,
  } = props;
  const hasItems = item.items.length > 0;
  const hasGroups = item.groups.length > 0;
  const shouldDivide = hasItems && hasGroups;

  return (
    <Grid2
      container
      sx={{
        alignItems: 'stretch',
      }}
    >
      {hasGroups && (
        <Grid2 size={{ xs: 'auto' }}>
          {item.groups.map((group, index) => (
            <Group
              key={group.id}
              isLastGroup={index >= item.groups.length - 1}
              item={group}
              onClick={onClick}
            />
          ))}
        </Grid2>
      )}
      {shouldDivide && (
        <Grid2 size={{ xs: 'auto' }}>
          <Divider orientation="vertical" />
        </Grid2>
      )}
      {hasItems && (
        <Grid2 size={{ xs: 'auto' }}>
          <List component="div">
            {item.items.map((menuItem) => (
              <Item
                key={menuItem.id}
                item={menuItem}
                onClick={onClick}
              />
            ))}
          </List>
        </Grid2>
      )}
    </Grid2>
  );
}

DesktopMenuItemMegaMenu.propTypes = {
  item: megaMenuShape,
  // Events
  onClick: PropTypes.func,
};

function Item(props) {
  const {
    item,
    dense,
    onClick,
  } = props;
  const {
    title,
    badge,
    badgeIcon,
    link,
  } = item;
  let { image } = item;
  const theme = useTheme();
  image = image?.formats?.thumbnail
    ?? image?.formats?.small
    ?? image?.formats?.medium
    ?? image?.formats?.large
    ?? image;

  const markedItems = [
    45, 46, 47, 48, 178, 176, 179, 182, 284, 281, 283, 282, 382, 383, 380, 381, 482, 481, 476, 474,
  ];

  if (!link) {
    console.log('Missing link object', item.id, item);
  }

  return (
    <LinkElement data={link} onClick={onClick}>
      <ListItem
        button
        className={item.cssClass}
        component="a"
        dense={dense}
        sx={{
          minWidth: 150,
        }}
      >
        {image && (
          <ListItemAvatar
            sx={{
              marginRight: theme.spacing(2),
            }}
          >
            <Image
              alt={title}
              height={image.height ?? 100}
              src={image.url}
              style={{
                display: 'block',
                width: 70,
                height: 'auto',
              }}
              width={image.width ?? 100}
            />
          </ListItemAvatar>
        )}
        <ListItemText>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <ModelName
              name={title}
              noWrap={false}
              sx={{
                marginRight: theme.spacing(1),
              }}
            />
            {markedItems.includes(item.id) && (
              <>
                <Image
                  alt="Hyundai"
                  height={618}
                  src="/images/car-brands/hyundai-motor-company-logo-2.svg"
                  style={{
                    marginRight: theme.spacing(1),
                    width: 24,
                    height: 24,
                  }}
                  width={618}
                />
                <Image
                  alt="MG Motor"
                  height={500}
                  src="/images/car-brands/mgmotors-logo.svg"
                  style={{
                    marginRight: theme.spacing(1),
                    width: 25,
                    height: 25,
                  }}
                  width={500}
                />
                <Image
                  alt="Mitsubishi"
                  height={172}
                  src="/images/car-brands/mitsubishi-logo.svg"
                  style={{
                    marginRight: theme.spacing(1),
                    width: 15,
                    height: 15,
                  }}
                  width={172}
                />
              </>
            )}
          </Box>
        </ListItemText>
        {badge && (
          <MenuItemBadge
            icon={badgeIcon}
            label={badge}
          />
        )}
      </ListItem>
    </LinkElement>
  );
}

Item.propTypes = {
  item: megaMenuItemShape,
  dense: PropTypes.bool,
  // Events
  onClick: PropTypes.func,
};

function Group(props) {
  const {
    item,
    isLastGroup,
    onClick,
  } = props;
  const {
    title,
    items,
  } = item;
  const columnSpan = items.length <= 1 ? 12 : 6;

  return (
    <>
      <Box
        className={item.cssClass}
        sx={{
          maxWidth: 700,
        }}
      >
        {title && (
          <Typography
            color="primary"
            component="div"
            sx={{
              px: 2,
              py: 1,
            }}
            variant="subtitle2"
          >
            {title}
          </Typography>
        )}
        <Grid2 container sx={{ alignItems: 'center' }}>
          {items.map((menuItem) => (
            <Grid2
              key={item.id}
              size={{ xs: columnSpan }}
            >
              <Item
                dense
                item={menuItem}
                onClick={onClick}
              />
            </Grid2>
          ))}
        </Grid2>
      </Box>
      {!isLastGroup && (
        <Divider sx={{ my: 1 }} />
      )}
    </>
  );
}

Group.propTypes = {
  item: megaMenuGroupShape,
  isLastGroup: PropTypes.bool.isRequired,
  // Events
  onClick: PropTypes.func,
};
