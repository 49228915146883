/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FiMapPin, FiPhoneCall, FiSearch, FiSettings,
} from 'react-icons/fi';
import {
  Box, IconButton, Typography, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { trackEvent } from './Tracking';
import { zIndices } from '@/theme';
import Backdrop from '../atoms/Backdrop';
import Link from '../atoms/Link';
import ScrollToTopFab from '../molecules/ScrollToTopFab';
import WhatsappFab from '../molecules/WhatsappFab';

const BREAKPOINT = 'md';

export default function FloatingActionBar(props) {
  const { data } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: zIndices.floatingActionBar,
        pointerEvents: 'none',
        // Position: mobile
        bottom: 0,
        left: 0,
        width: '100%',
        height: 'auto',
        [theme.breakpoints.up(BREAKPOINT)]: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          // Position: desktop
          top: 0,
          right: 0,
          left: 'auto',
          bottom: 'auto',
          width: 'auto',
          height: '100%',
        },
      }}
    >
      <Backdrop
        sx={{
          display: {
            xs: 'flex',
            [BREAKPOINT]: 'none',
          },
          pointerEvents: 'all',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: theme.spacing(1),
          '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
          },
        }}
      >
        <Actions
          color="default"
          data={data}
        />
      </Backdrop>
      <Backdrop
        sx={{
          display: {
            xs: 'none',
            [BREAKPOINT]: 'flex',
          },
          pointerEvents: 'all',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: theme.spacing(1),
          '& > *:not(:last-child)': {
            marginRight: 0,
            marginBottom: theme.spacing(1),
          },
          backgroundColor: `${theme.palette.primary.light} !important`,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
          '& > *': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}
      >
        <Actions
          color="secondary"
          data={data}
          isDesktop
        />
      </Backdrop>
    </Box>
  );
}

FloatingActionBar.propTypes = {
  data: PropTypes.object,
};

function Actions(props) {
  const {
    data,
    color,
    isDesktop,
  } = props;
  const { t } = useTranslation();
  const {
    sidebarPhone,
    sidebarUrlLocations,
    sidebarUrlWorkshop,
    sidebarUrlCarsearch,
  } = data ?? {};

  return (
    <>
      {!isDesktop && (
        <span style={{ marginRight: 'auto' }}>
          <WhatsappFab
            iconButton
            phoneNumbers={data.whatsappNumbers}
          />
        </span>
      )}
      <Link href={`tel:${sidebarPhone}`} legacyBehavior>
        <IconButton
          aria-label="Anruf"
          color={color}
          component="a"
          onClick={() => {
            trackEvent('Contact');
            trackEvent('ContactCustom', {
              channel: 'Phone',
              origin: 'Floating Action Bar',
            });
          }}
        >
          <FiPhoneCall style={{ display: 'block' }} />
        </IconButton>
      </Link>
      {isDesktop && (
        <Typography color="secondary" variant="caption">
          {t('components.organisms.FloatingActionBar.call')}
        </Typography>
      )}
      <Link href={sidebarUrlLocations ?? '#'} legacyBehavior>
        <IconButton
          aria-label="Standorte"
          color={color}
          component="a"
          onClick={() => {
            trackEvent('Contact');
            trackEvent('ContactCustom', {
              channel: 'Branches',
              origin: 'Floating Action Bar',
            });
          }}
        >
          <FiMapPin style={{ display: 'block' }} />
        </IconButton>
      </Link>
      {isDesktop && (
        <Typography color="secondary" variant="caption">
          {t('components.organisms.FloatingActionBar.locations')}
        </Typography>
      )}
      <Link href={sidebarUrlWorkshop ?? '#'} legacyBehavior>
        <IconButton
          aria-label="Werkstatt"
          color={color}
          component="a"
          onClick={() => {
            trackEvent('Contact');
            trackEvent('ContactCustom', {
              channel: 'WorkshopAppointment',
              origin: 'Floating Action Bar',
            });
          }}
        >
          <FiSettings style={{ display: 'block' }} />
        </IconButton>
      </Link>
      {isDesktop && (
        <Typography color="secondary" variant="caption">
          {t('components.organisms.FloatingActionBar.workshop')}
        </Typography>
      )}
      <Link href={sidebarUrlCarsearch ?? '#'} legacyBehavior>
        <IconButton
          aria-label="Fahrzeuge"
          color={color}
          component="a"
          onClick={() => {
            trackEvent('Contact');
            trackEvent('ContactCustom', {
              channel: 'Vehicles',
              origin: 'Floating Action Bar',
            });
          }}
        >
          <FiSearch style={{ display: 'block' }} />
        </IconButton>
      </Link>
      {isDesktop && (
        <Typography color="secondary" variant="caption">
          {t('components.organisms.FloatingActionBar.vehicles')}
        </Typography>
      )}
      {!isDesktop && (
        <Box style={{ marginLeft: 'auto' }}>
          <ScrollToTopFab iconButton />
        </Box>
      )}
    </>
  );
}

Actions.propTypes = {
  color: PropTypes.string,
  isDesktop: PropTypes.bool,
  data: PropTypes.shape({
    sidebarPhone: PropTypes.string,
    sidebarUrlLocations: PropTypes.string,
    sidebarUrlWorkshop: PropTypes.string,
    sidebarIrlCarsearch: PropTypes.string,
  }),
};
