import {
  AppBar, Box, Button, Collapse, Container, Fade, IconButton, Link as MuiLink, useTheme,
} from '@mui/material';
import { FiMenu, FiSearch, FiX } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Backdrop from '@components/atoms/Backdrop';
import BrandLogo from '@components/molecules/BrandLogo';
import Breadcrumbs from '@components/molecules/Breadcrumbs';
import Image from '@components/atoms/Image2';
import Link from '@components/atoms/Link';
import MainMenu from '@components/molecules/MainMenu';
import SearchBar from '@components/molecules/SearchBar';
import LanguagePopover from './LanguagePopover';
import { defaultBrandLogoSize, zIndices } from '@/theme';
import { LOCALES } from '@/constants';

const defaultHeightDesktop = 100 - 24; // Breadcrumbs height
const defaultHeightMobile = 90 - 24; // Breadcrumbs height
export const collapsedHeightDesktop = '70px';
export const collapsedHeightMobile = '60px';
const BREAKPOINT = 'md';

export default function Header(props) {
  const {
    data,
    breadcrumbs,
  } = props;
  const router = useRouter();
  const theme = useTheme();
  const { t } = useTranslation();
  const [isMounted, setMounted] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [ref, inView] = useInView();

  const {
    navigationMain,
    pageUrls,
  } = data;

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleToggleMenu = () => {
    const shouldBeOpen = !isMenuOpen;
    setMenuOpen(shouldBeOpen);

    if (shouldBeOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };
  const handleCloseMenu = () => {
    setMenuOpen(false);
    document.body.classList.remove('no-scroll');
  };
  const handleSearch = (value) => {
    const url = `/search?q=${encodeURIComponent(value)}`;
    window.scrollTo(0, 0);
    router.replace(url, undefined, { shallow: true });
  };

  const shouldCollapse = isMounted && !inView;

  return (
    <>
      <StyledAppBar
        elevation={0}
        id="header"
        position="fixed"
        shouldCollapse={shouldCollapse}
      >
        <Backdrop>
          <Collapse in={!shouldCollapse}>
            <Container>
              <Breadcrumbs data={breadcrumbs} />
            </Container>
          </Collapse>
          <Container
            className="container"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              transition: theme.transitions.create('height', {
                duration: theme.transitions.duration.complex,
              }),
              '& a:not(:last-child), button:not(:last-child)': {
                marginRight: `${theme.spacing()}`,
              },
            }}
          >
            <Link href="/" legacyBehavior>
              <Box
                component="a"
                onClick={handleCloseMenu}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <BrandLogo
                  className="brandLogo"
                  style={{
                    display: 'block',
                    marginRight: theme.spacing(),
                    transition: theme.transitions.create(['width', 'height'], {
                      duration: theme.transitions.duration.complex,
                    }),
                  }}
                />
              </Box>
            </Link>
            <Box sx={{
              flexGrow: 1,
              display: {
                xs: 'block',
                [BREAKPOINT]: 'none',
              },
            }}
            />
            <SearchBar
              onSubmit={handleSearch}
              search={router.query.q}
              sx={{
                display: {
                  xs: 'none',
                  [BREAKPOINT]: 'block',
                },
                flexGrow: 1,
                marginLeft: theme.spacing(5),
                marginRight: theme.spacing(5),
              }}
              t={t}
            />
            <Link href="/hyundai" legacyBehavior>
              <MuiLink
                className="hyundai_tag brand_logo_origin"
                sx={{
                  display: {
                    xs: 'none',
                    [BREAKPOINT]: 'block',
                  },
                }}
              >
                <Image
                  alt="Hyundai"
                  className="carBrandLogoHyundaiOrigin carBrandLogo"
                  height={27}
                  src="/images/car-brands/hyundai-motor-company-logo-origin.svg"
                  style={{
                    filter: 'none',
                    display: 'block',
                  }}
                  width={185}
                />
              </MuiLink>
            </Link>
            <Link href="/hyundai" legacyBehavior>
              <MuiLink
                className="hyundai_tag brand_logo_stylized"
                sx={{
                  display: {
                    xs: 'none',
                    [BREAKPOINT]: 'block',
                  },
                }}
              >
                <Image
                  alt="Hyundai"
                  className="carBrandLogoHyundai carBrandLogo"
                  height={618}
                  src="/images/car-brands/hyundai-motor-company-logo-2.svg"
                  style={{
                    display: 'block',
                  }}
                  width={618}
                />
              </MuiLink>
            </Link>
            <Link href="/mg-motor" legacyBehavior>
              <MuiLink
                className="mgmotor_tag brand_logo_origin"
                sx={{
                  display: {
                    xs: 'none',
                    [BREAKPOINT]: 'block',
                  },
                }}
              >
                <Image
                  alt="MG Motor"
                  className="carBrandLogoMGOrigin carBrandLogo"
                  height={500}
                  src="/images/car-brands/mgmotors-logo.svg"
                  style={{
                    filter: 'none',
                    display: 'block',
                  }}
                  width={500}
                />
              </MuiLink>
            </Link>
            <Link href="/mg-motor" legacyBehavior>
              <MuiLink
                className="mgmotor_tag brand_logo_stylized"
                sx={{
                  display: {
                    xs: 'none',
                    [BREAKPOINT]: 'block',
                  },
                }}
              >
                <Image
                  alt="MG Motor"
                  className="carBrandLogoMG carBrandLogo"
                  height={500}
                  src="/images/car-brands/mgmotors-logo.svg"
                  style={{
                    opacity: 0.5,
                    display: 'block',
                  }}
                  width={500}
                />
              </MuiLink>
            </Link>
            <Link href="/mitsubishi" legacyBehavior>
              <MuiLink
                className="mitsubishi_tag brand_logo_origin"
                sx={{
                  display: {
                    xs: 'none',
                    [BREAKPOINT]: 'block',
                  },
                }}
              >
                <Image
                  alt="Mitsubishi"
                  className="carBrandLogoMitsubishiOrigin carBrandLogo"
                  height={172}
                  src="/images/car-brands/mitsubishi-logo.svg"
                  style={{
                    filter: 'none',
                    display: 'block',
                  }}
                  width={172}
                />
              </MuiLink>
            </Link>
            <Link href="/mitsubishi" legacyBehavior>
              <MuiLink
                className="mitsubishi_tag brand_logo_stylized"
                sx={{
                  display: {
                    xs: 'none',
                    [BREAKPOINT]: 'block',
                  },
                }}
              >
                <Image
                  alt="Mitsubishi"
                  className="carBrandLogoMitsubishi carBrandLogo"
                  height={172}
                  src="/images/car-brands/mitsubishi-logo.svg"
                  style={{
                    display: 'block',
                  }}
                  width={172}
                />
              </MuiLink>
            </Link>
            <Link href="/fuso" legacyBehavior>
              <MuiLink
                className="fuso_tag brand_logo_origin"
                sx={{
                  display: {
                    xs: 'none',
                    [BREAKPOINT]: 'block',
                  },
                }}
              >
                <Image
                  alt="FUSO"
                  className="carBrandLogoFusoOrigin carBrandLogo"
                  height={317}
                  src="/images/car-brands/mitsubishi-fuso-logo.svg"
                  style={{
                    filter: 'none',
                    display: 'block',
                  }}
                  width={317}
                />
              </MuiLink>
            </Link>
            <Link href="/fuso" legacyBehavior>
              <MuiLink
                className="fuso_tag brand_logo_stylized"
                sx={{
                  display: {
                    xs: 'none',
                    [BREAKPOINT]: 'block',
                  },
                }}
              >
                <Image
                  alt="FUSO"
                  className="carBrandLogoFuso carBrandLogo"
                  height={317}
                  src="/images/car-brands/mitsubishi-fuso-logo.svg"
                  style={{
                    display: 'block',
                  }}
                  width={317}
                />
              </MuiLink>
            </Link>
            <Link href="/maxus" legacyBehavior>
              <MuiLink
                className="maxus_tag brand_logo_origin"
                sx={{
                  display: {
                    xs: 'none',
                    [BREAKPOINT]: 'block',
                  },
                }}
              >
                <Image
                  alt="Maxus"
                  className="carBrandLogoMaxus carBrandLogo"
                  height={500}
                  src="/images/car-brands/maxus-logo.png"
                  style={{
                    filter: 'none',
                    display: 'block',
                  }}
                  width={500}
                />
              </MuiLink>
            </Link>
            <Link href="/maxus" legacyBehavior>
              <MuiLink
                className="maxus_tag brand_logo_stylized"
                sx={{
                  display: {
                    xs: 'none',
                    [BREAKPOINT]: 'block',
                  },
                }}
              >
                <Image
                  alt="Maxus"
                  className="carBrandLogoMaxus carBrandLogo"
                  height={500}
                  src="/images/car-brands/maxus-logo.png"
                  style={{
                    filter: 'none',
                    display: 'block',
                  }}
                  width={500}
                />
              </MuiLink>
            </Link>
            <Link href="/search" legacyBehavior>
              <Button
                aria-label="search"
                component="a"
                endIcon={<FiSearch />}
                sx={{
                  display: {
                    [BREAKPOINT]: 'none',
                    xs: 'flex',
                  },
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  color: theme.palette.text.primary,
                }}
                variant="outlined"
              >
                {t('components.organisms.Header.search')}
              </Button>
            </Link>
            <LanguageButton
              locales={data.locales.data}
              pageUrls={pageUrls}
              sx={{
                display: {
                  xs: 'none',
                  [BREAKPOINT]: 'block',
                },
                marginLeft: theme.spacing(2),
              }}
            />
            <IconButton
              aria-label="menu"
              onClick={handleToggleMenu}
              sx={{
                display: {
                  xs: 'block',
                  [BREAKPOINT]: 'none',
                },
              }}
            >
              {isMenuOpen ? <FiX style={{ display: 'block' }} /> : <FiMenu style={{ display: 'block' }} />}
            </IconButton>
          </Container>
          <Collapse
            in={!shouldCollapse}
            sx={{
              display: {
                xs: 'none',
                [BREAKPOINT]: 'block',
              },
            }}
          >
            <MainMenu
              desktop
              items={navigationMain}
              onClick={handleCloseMenu}
              pageUrls={pageUrls}
            />
          </Collapse>
        </Backdrop>
      </StyledAppBar>
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          width: '100%',
          height: '1px',
          top: `${defaultHeightDesktop}px`,
        }}
      />
      <Fade
        in={isMenuOpen}
        sx={{
          position: 'fixed',
          display: {
            xs: 'flex',
            [BREAKPOINT]: 'none',
          },
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          flexDirection: 'column',
          paddingTop: `calc(${defaultHeightMobile}px + ${theme.spacing()})`,
          color: theme.palette.secondary.contrastText,
          background: theme.palette.secondary.main,
          zIndex: zIndices.mobileMainMenu,
          overflowY: 'auto',
          '-webkit-overflow-scrolling': 'touch',
          '& ul > li': {
            marginBottom: theme.spacing(2),
          },
          transformOrigin: 'right top',
        }}
        timeout={400}
      >
        <Container>
          <MainMenu
            items={navigationMain}
            locales={data.locales.data}
            mobile
            onClick={handleCloseMenu}
            pageUrls={pageUrls}
            sx={{ py: 5 }}
          />
        </Container>
      </Fade>
    </>
  );
}

function LanguageButton({
  locales,
  pageUrls,
  sx,
}) {
  const anchorRef = useRef(null);
  const { i18n } = useTranslation();
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="language"
        onClick={handleOpenPopover}
        sx={sx}
      >
        <Image
          alt={i18n.language}
          height={36}
          src={LOCALES[i18n.language]?.icon}
          style={{
            display: 'block',
            height: 20,
            width: 20,
            objectFit: 'contain',
          }}
          width={36}
        />
      </IconButton>
      <LanguagePopover
        anchorEl={anchorRef.current}
        locales={locales}
        onClose={handleClosePopover}
        open={openPopover}
        pageUrls={pageUrls}
        variant="desktop"
      />
    </>
  );
}

Header.propTypes = {
  data: PropTypes.object,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
};

const StyledAppBar = styled(AppBar)(({
  theme,
  shouldCollapse,
}) => ({
  zIndex: zIndices.header,
  marginTop: '-1px', // iOS clipping bug top
  backgroundColor: 'transparent',
  borderBottom: '0.1rem solid rgba(0, 0, 0, 0.05)',
  '& .carBrandLogo': {
    marginRight: theme.spacing(2),
    transition: theme.transitions.create(['width', 'height', 'filter', 'opacity'], {
      duration: theme.transitions.duration.complex,
    }),
    filter: 'grayscale(1) brightness(2)',
    '&:hover, &:active': {
      filter: 'none',
      opacity: '1 !important',
    },
  },
  ...(shouldCollapse ? ({
    '& .container': {
      height: collapsedHeightMobile,
    },
    '& .brandLogo': {
      width: 'auto',
      height: '30px',
    },
  }) : ({
    '& .container': {
      height: `${defaultHeightMobile}px`,
    },
    '& .brandLogo': {
      width: 'auto',
      height: '38px',
    },
  })),
  [theme.breakpoints.up('md')]: {
    ...(shouldCollapse ? ({
      '& .container': {
        height: collapsedHeightDesktop,
      },
      '& .brandLogo': {
        height: '36px',
      },
      '& .carBrandLogoSeal': {
        width: 60,
        height: 'auto',
      },
      '& .carBrandLogoHyundai': {
        width: 60,
        height: 'auto',
      },
      '& .carBrandLogoHyundaiOrigin': {
        width: 140,
        height: 'auto',
      },
      '& .carBrandLogoMG': {
        width: 30,
        height: 'auto',
      },
      '& .carBrandLogoMGOrigin': {
        width: 30,
        height: 'auto',
      },
      '& .carBrandLogoMitsubishi': {
        width: 40,
        height: 'auto',
      },
      '& .carBrandLogoMitsubishiOrigin': {
        width: 40,
        height: 'auto',
      },
      '& .carBrandLogoFuso': {
        width: 40,
        height: 'auto',
      },
      '& .carBrandLogoFusoOrigin': {
        width: 40,
        height: 'auto',
      },
      '& .carBrandLogoMaxus': {
        width: 50,
        height: 'auto',
        filter: 'none !important',
      },
      '& .carBrandLogoMaxusOrigin': {
        width: 50,
        height: 'auto',
      },
      '& .carBrandLogoTropos': {
        width: 60,
        height: 'auto',
      },
      '& .carBrandLogoTroposOrigin': {
        width: 60,
        height: 'auto',
      },
    }) : ({
      '& .container': {
        height: `${defaultHeightDesktop}px`,
      },
      '& .brandLogo': {
        width: 'auto',
        height: `${defaultBrandLogoSize}px`,
      },
      '& .carBrandLogoSeal': {
        width: 70,
        height: 'auto',
      },
      '& .carBrandLogoHyundai': {
        width: 70,
        height: 'auto',
      },
      '& .carBrandLogoHyundaiOrigin': {
        width: 190,
        height: 'auto',
      },
      '& .carBrandLogoMG': {
        width: 40,
        height: 'auto',
      },
      '& .carBrandLogoMGOrigin': {
        width: 40,
        height: 'auto',
      },
      '& .carBrandLogoMitsubishi': {
        width: 50,
        height: 'auto',
      },
      '& .carBrandLogoMitsubishiOrigin': {
        width: 50,
        height: 'auto',
      },
      '& .carBrandLogoFuso': {
        width: 50,
        height: 'auto',
      },
      '& .carBrandLogoFusoOrigin': {
        width: 50,
        height: 'auto',
      },
      '& .carBrandLogoMaxus': {
        width: 60,
        height: 'auto',
        filter: 'none !important',
      },
      '& .carBrandLogoMaxusOrigin': {
        width: 70,
        height: 'auto',
      },
      '& .carBrandLogoTropos': {
        width: 80,
        height: 'auto',
      },
      '& .carBrandLogoTroposOrigin': {
        width: 80,
        height: 'auto',
      },
    })),
  },
}));
