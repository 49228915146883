import { ContentBlockRenderer } from './render/ContentBlockRenderer';

export default function bootstrap(options) {
  const { components } = options;

  let graphQlFragments = '';

  components.forEach((component) => {
    ContentBlockRenderer.registerComponent(component.typeName, component);
    graphQlFragments += `${component.graphQlSchema}\n`;
  });

  return {
    graphQlFragments: graphQlFragments.trim(),
  };
}
